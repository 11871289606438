/* eslint-disable */
import * as CodeMirror from "codemirror";
import { CSSLint } from "csslint";
import { JSHINT } from "jshint";
import { HTMLHint } from "htmlhint";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/html-lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/css-lint";
import "codemirror/addon/comment/comment";
import "codemirror/addon/dialog/dialog";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/search/search";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/wrap/hardwrap";
import "codemirror/keymap/sublime";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/xml/xml";

// Make CodeMirror available globally so the modes' can register themselves.
Object.assign(window, {
  CodeMirror,
  HTMLHint,
  JSHINT,
  CSSLint,
});

// Set CodeMirror Defaults
Object.assign(CodeMirror.defaults, {
  autoCloseBrackets: true,
  autoRefresh: { delay: 300 },
  extraKeys: {
    "Ctrl-Q": (codeMirror) => codeMirror.foldCode(codeMirror.getCursor()),
    "Ctrl-Y": (codeMirror) => codeMirror.commands.foldAll(codeMirror),
    "Ctrl-I": (codeMirror) => codeMirror.commands.unfoldAll(codeMirror),
  },
  foldGutter: true,
  gutter: true,
  gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
  indentWithTabs: true,
  keyMap: "sublime",
  lineNumbers: true,
  lineWrapping: true,
  lint: true,
  matchBrackets: true,
  showCursorWhenSelecting: true,
  styleActiveLine: true,
  tabSize: 4,
});
